import crossIcon from "assets/icons/cross.svg";
import clsx from "clsx";
import React, { FC, memo, useCallback } from "react";

import logoIcon from "../../assets/icons/logo.svg";
import { Location } from "../../lib/models/location";
import styles from "./LocationCard.module.scss";
import { LocationTagProps } from "./LocationTags/LocationTags";

export type LocationCardProps = Location & {
  address?: string;
  description?: string;
  tags?: LocationTagProps[];
  isActive?: boolean;
  detailView?: boolean;
  isClosable?: boolean;
  onClose?: () => void;
  onClick?: (place_id: string) => void;
};

const LocationCard: FC<LocationCardProps> = ({
  name,
  price_level,
  vicinity,
  address,
  description,
  place_id,
  tags = [],
  website,
  is_recommended,
  isActive,
  detailView,
  isClosable = false,
  onClose,
  onClick,
}) => {
  const prices = Array.from({ length: price_level }).fill("$") as string[];

  const handlerOnClickLocation = useCallback(() => {
    if (!place_id) return;

    onClick?.(place_id);
  }, [onClick, place_id]);

  return (
    <div
      className={clsx(
        styles.container,
        { [styles.isActive]: isActive },
        { [styles.detailViewContainer]: detailView },
        { [styles.recommended]: is_recommended },
      )}
      onClick={handlerOnClickLocation}
      id={"location" + place_id}
    >
      <div>
        <div
          className={clsx(styles.nameWrapper, {
            [styles.detailViewWrapper]: detailView,
          })}
        >
          <h3 className={styles.name}>
            {name}
            {is_recommended && !detailView && (
              <div className={styles.isRecommendedLogo}>
                <img src={logoIcon} alt="logo" />
              </div>
            )}
          </h3>

          <div
            className={clsx(styles.priceContainer, {
              [styles.detailPriceContainer]: detailView,
            })}
          >
            {prices}
          </div>
          {isClosable && (
            <button className={styles.closeButton} onClick={onClose}>
              <img src={crossIcon} alt="close" />
            </button>
          )}
        </div>
        <div className={styles.description}>{vicinity}</div>
      </div>
      {description && <div className={styles.description}>{description}</div>}
      {website && (
        <a
          className={styles.description}
          href={website}
          target="_blank"
          rel="noreferrer"
        >
          {website}
        </a>
      )}
      <div className={`${styles.description} ${styles.tagsContainer}`}>
        {tags.map((tag) => tag.name.replaceAll("_", " ")).join(", ")}
      </div>
    </div>
  );
};

export default memo(LocationCard);
