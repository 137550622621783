import React, { FC } from "react";

import { IconProps } from "../types";

const DirectionIcon: FC<IconProps> = ({ color = "black" }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179672_7909)">
        <rect
          width="14"
          height="14"
          transform="translate(0.774292 0.670502)"
          fill={color || "currentColor"}
          fillOpacity="0.01"
        />
        <g clipPath="url(#clip1_179672_7909)">
          <path
            d="M14.3279 7.46849L1.25762 0.915364C1.20449 0.888802 1.14356 0.882552 1.08574 0.896614C1.02158 0.912477 0.966317 0.953117 0.932053 1.00964C0.89779 1.06616 0.887318 1.13395 0.902932 1.19818L2.24981 6.7013C2.27012 6.78411 2.33106 6.8513 2.41231 6.87786L4.72012 7.67005L2.41387 8.46224C2.33262 8.49036 2.27168 8.55599 2.25293 8.6388L0.902932 14.1497C0.888869 14.2076 0.895119 14.2685 0.921682 14.3201C0.982619 14.4435 1.13262 14.4935 1.25762 14.4326L14.3279 7.91693C14.3764 7.89349 14.4154 7.85286 14.4404 7.80599C14.5014 7.68099 14.4514 7.53099 14.3279 7.46849ZM2.44356 12.5841L3.22949 9.37161L7.84199 7.7888C7.87793 7.7763 7.90762 7.74818 7.92012 7.71068C7.94199 7.64505 7.90762 7.57474 7.84199 7.5513L3.22949 5.97005L2.44668 2.77005L12.2592 7.69036L2.44356 12.5841Z"
            fill={color || "currentColor"}
            fillOpacity="0.85"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_179672_7909">
          <rect
            width="14"
            height="14"
            fill={color || "currentColor"}
            transform="translate(0.774292 0.670502)"
          />
        </clipPath>
        <clipPath id="clip1_179672_7909">
          <rect
            width="14"
            height="14"
            fill={color || "currentColor"}
            transform="translate(0.774292 0.670502)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DirectionIcon;
