import { Spin } from "antd";
import googlePinIcon from "assets/icons/google-pin-recommended.svg";
import LocationDetailsCard from "components/LocationDetailsCard/LocationDetailsCard";
import SharedLinkModal from "components/SharedLinkModal/SharedLinkModal";
import MapLayout from "containers/MapLayout/MapLayout";
import { useFetchHalfway } from "hooks/useFetchHalfway";
import { useNotFoundRedirect } from "hooks/useNotFoundRedirect";
import { HalfwayService } from "lib/services/halfway/halfway.service";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Location } from "../../lib/models/location";

const HalfwayLocation = () => {
  const { halfwayId, locationId } = useParams<{
    halfwayId: string;
    locationId: string;
  }>();

  const { fetchHalfway } = useFetchHalfway();
  const { notFoundRedirect } = useNotFoundRedirect();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [sharedLocation, setSharedLocation] = useState<Location | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const onLoad = useCallback((map: google.maps.Map | null) => {
    setMap(map);
  }, []);

  const handleSearchResults = useCallback(
    (place: Location) => {
      setSharedLocation(place);

      const infoWindow = new google.maps.InfoWindow({
        content: place.vicinity,
      });

      const marker = new google.maps.Marker({
        position: { lat: place.geometry.lat, lng: place.geometry.lng },
        clickable: true,
        title: place.vicinity,
        icon: googlePinIcon,
      });

      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: true,
        });

        map?.setZoom(15);
        map?.panTo(marker.getPosition()!);
      });
      marker.setMap(map);
    },
    [map],
  );

  useEffect(() => {
    if (!map || !halfwayId || !locationId) return;

    const showPlaces = async () => {
      setLoading(true);
      const halfway = await fetchHalfway(halfwayId);

      const halfwayService = new HalfwayService(
        map,
        halfway.address1,
        halfway.address2,
        halfway.occasion,
      );
      await halfwayService.calculateHalfwayPointWithTrafficOffset();

      halfwayService.drawSearchAreaCircle();

      const place = await halfwayService.getPlaceDetails(locationId);

      handleSearchResults(place);
    };

    showPlaces()
      .catch((error) => {
        void notFoundRedirect();
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    fetchHalfway,
    halfwayId,
    handleSearchResults,
    locationId,
    map,
    notFoundRedirect,
  ]);

  return (
    <MapLayout onLoad={onLoad} header={<SharedLinkModal />}>
      {loading ? (
        <Spin size="large" />
      ) : (
        <LocationDetailsCard
          locationInfo={sharedLocation}
          isShared
          halfwayId={halfwayId ?? ""}
        />
      )}
    </MapLayout>
  );
};

export default HalfwayLocation;
