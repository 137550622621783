import { message } from "antd";
import { getLocations, removeLocation } from "api/locations";
import PageLoader from "components/ui/PageLoader/PageLoader";
import AdminLayout from "containers/AdminLayout/AdminLayout";
import React, { memo, useCallback, useEffect, useState } from "react";

import { Location } from "../../lib/models/location";
import LocationsTable from "./LocationsTable";

const AdminPanelLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onRemoveLocation = useCallback(async (placeId: string) => {
    try {
      setIsLoading(true);

      await removeLocation(placeId);
      void message.success("Location has removed successfully.");
    } catch (error) {
      console.error(error);
      void message.error("Something went wrong during removing location");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    void getLocations().then((locations) => {
      setLocations(locations);
    });
  }, []);

  return (
    <AdminLayout>
      {isLoading && <PageLoader />}
      <LocationsTable data={locations} onRemoveItem={onRemoveLocation} />
    </AdminLayout>
  );
};

export default memo(AdminPanelLocations);
