import GoogleMap from "components/GoogleMap/GoogleMap";
import { disableScroll, enableScroll } from "lib/utils/dom";
import React, { FC, useEffect } from "react";

import styles from "./MapLayout.module.scss";

export type MapLayoutProps = {
  onLoad: (map: google.maps.Map) => void;
  header: React.ReactNode;
  children: React.ReactNode;
};

const MapLayout: FC<MapLayoutProps> = ({ onLoad, header, children }) => {
  useEffect(() => {
    disableScroll();

    return () => {
      enableScroll();
    };
  }, []);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.betaContainer}>BETA</div>
      {/* Google maps */}
      <div className={styles.googleMapsContainer}>
        {header}
        <GoogleMap
          handleOnLoad={onLoad}
          mapClassName={styles.googleMapsContainer}
        />
      </div>

      {/* Locations */}
      <div className={styles.locationsContainer}>{children}</div>
    </div>
  );
};

export default MapLayout;
