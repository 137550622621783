import facebook from "assets/icons/facebook.svg";
import instagram from "assets/icons/instagram.svg";
import youtube from "assets/icons/youtube.svg";

import { AppRoutesEnum } from "./global";

export const SOCIAL_MEDIA_LINKS = [
  { icon: facebook, url: "https://www.facebook.com/", alt: "facebook" },
  { icon: instagram, url: "/instagram", alt: "instagram" },
  { icon: youtube, url: "/cookies", alt: "youtube" },
];

export const FOOTER_LINKS = [
  { title: "Private Policy", url: AppRoutesEnum.PrivatePolicy },
  { title: "Terms of Service", url: AppRoutesEnum.TermsOfService },
  { title: "Cookies", url: AppRoutesEnum.Cookies },
];
