import { AppRoutesEnum } from "lib/constants/global";
import AdminPanelLocations from "pages/AdminPanelLocations/AdminPanelLocations";
import AdminPanelUpload from "pages/AdminPanelUpload/AdminPanelUpload";
import HalfwayResult from "pages/HalfwayResult/HalfwayResult";
import HalfwaySharedLocation from "pages/HalfwaySharedLocation/HalfwaySharedLocation";
import LocationDirection from "pages/LocationDirection";
import LocationSearchArea from "pages/LocationSearchArea";
import MeetingCreating from "pages/MeetingCreating/MeetingCreating";
import PrivatePolicy from "pages/PrivatePolicy/PrivatePolicy";
import React from "react";
import { Navigate } from "react-router-dom";

import AdminPanelImportExport from "./pages/AdminPanelImportExport/AdminPanelImportExport";

export const ROUTES = [
  {
    path: AppRoutesEnum.ROOT,
    element: <MeetingCreating />,
  },
  { path: "/search", element: <LocationDirection /> },
  { path: AppRoutesEnum.HalfwayResult, element: <HalfwayResult /> },
  {
    path: AppRoutesEnum.HalfwaySharedLocation,
    element: <HalfwaySharedLocation />,
  },
  {
    path: AppRoutesEnum.PrivatePolicy,
    element: <PrivatePolicy />,
  },
  {
    path: AppRoutesEnum.TermsOfService,
    element: <PrivatePolicy />,
  },
  {
    path: AppRoutesEnum.Cookies,
    element: <PrivatePolicy />,
  },
  {
    path: AppRoutesEnum.AdminPanelUpload,
    element: <AdminPanelUpload />,
  },
  {
    path: AppRoutesEnum.AdminPanelLocations,
    element: <AdminPanelLocations />,
  },
  {
    path: AppRoutesEnum.AdminPanelImportExport,
    element: <AdminPanelImportExport />,
  },
  {
    path: AppRoutesEnum.HalfwaySearch,
    element: <LocationSearchArea />,
  },
  {
    path: "*",
    element: <Navigate to={AppRoutesEnum.ROOT} />,
  },
];
