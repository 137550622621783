import GoogleMap from "components/GoogleMap/GoogleMap";
import { HalfwayService } from "lib/services/halfway/halfway.service";
import { memo, useCallback, useEffect, useState } from "react";

///TODO REMOVE PAGE
const LocationForm = () => {
  const [map, setMap] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoad = useCallback(async (map: any) => {
    const bounds = {
      north: 34.305_242_736_625_516,
      south: 33.696_144_875_163_625,
      west: -118.827_301_757_812_52,
      east: -118.159_119_873_046_86,
    };

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();

    directionsRenderer.setMap(map);

    const loc1 = {
      lat: 33.828_527_3,
      lng: -118.313_650_5,
    };

    const loc2 = {
      lat: 34.137_546_4,
      lng: -118.415_786_8,
    };
    // { lat: 34.1048185, lng: -118.2673298 };

    const googleMapHelpers = new HalfwayService(
      map,
      { coordinates: loc1 },
      { coordinates: loc2 },
      "*",
    );

    const lineCoordinates = [loc1, loc2];
    const line = new google.maps.Polyline({
      path: lineCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1,
      strokeWeight: 2,
    });

    line.setMap(map);

    const HALFWAY =
      await googleMapHelpers.calculateHalfwayPointWithTrafficOffset();

    googleMapHelpers.drawSearchAreaCircle();

    const markerLoc3 = new google.maps.Marker({
      position: HALFWAY,
      clickable: true,
    });

    // markerLoc1.setMap(map);
    // markerLoc2.setMap(map);
    markerLoc3.setMap(map);

    const requestOptions: Pick<
      google.maps.DirectionsRequest,
      | "travelMode"
      | "drivingOptions"
      | "provideRouteAlternatives"
      | "optimizeWaypoints"
      | "transitOptions"
    > = {
      travelMode: google.maps.TravelMode.DRIVING,
      drivingOptions: {
        departureTime: new Date(Date.now()),
        trafficModel: google.maps.TrafficModel.BEST_GUESS,
      },

      provideRouteAlternatives: true,
      optimizeWaypoints: true,
      transitOptions: {
        departureTime: new Date(Date.now()),
      },
    };

    console.log("request-HALFWAY", googleMapHelpers.halfWayPoint);

    const request: google.maps.DirectionsRequest = {
      origin: loc1,
      destination: { lat: 33.983_036_85, lng: -118.364_718_65 },
      ...requestOptions,
    };

    const request1: google.maps.DirectionsRequest = {
      origin: loc2,
      destination: { lat: 33.983_036_85, lng: -118.364_718_65 },
      ...requestOptions,
    };

    const getTrafficTime = async (
      request: google.maps.DirectionsRequest,
    ): Promise<number> =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) =>
        directionsService.route(request, function (result, status) {
          if (status === google.maps.DirectionsStatus.OK) {
            const routeLegs = result?.routes[0]?.legs[0];

            const routeDuration =
              routeLegs?.duration_in_traffic?.value ||
              routeLegs?.duration?.value ||
              0;

            resolve(routeDuration);
          } else {
            reject("Google Route Error");
          }
        }),
      );

    const location1Duration = await getTrafficTime(request);
    const location2Duration = await getTrafficTime(request1);

    // less than 5 minutes - ignore
    // less than 10 minutes - change point to 3km closer
    // less than 15 minutes - change point to 5km closer
    // less than 20 minutes - change point to 6km closer
    // less than 30 minutes - change point to 10km closer
    // more than 30 minutes - change point to 12km closer

    console.table({ location1Duration, location2Duration });

    const timeDifference = Math.abs(location1Duration - location2Duration);
    console.log("timeDifference", timeDifference);

    // Обчислення нових координат для точки center, зсунутої на 1 кілометр в напрямку point1

    const newrequest: google.maps.DirectionsRequest = {
      origin: loc1,
      destination: HALFWAY,
      ...requestOptions,
    };

    const newrequest2: google.maps.DirectionsRequest = {
      origin: loc2,
      destination: HALFWAY,
      ...requestOptions,
    };

    const location1DurationShift = await getTrafficTime(newrequest);
    const location2DurationShift = await getTrafficTime(newrequest2);
    console.table({ location1DurationShift, location2DurationShift });

    //duration_in_traffic

    // getDistanceMatrix(map);
    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(map);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  useEffect(() => {
    if (!map) return;
  }, [map]);

  return (
    <div className="App">
      <GoogleMap handleOnLoad={onLoad} />
    </div>
  );
};

export default memo(LocationForm);
