import "@react-google-maps/api";

import { Input } from "antd";
import Layout from "containers/MainLayout/Layout";
import { useCallback } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";

import { useAuth } from "../../lib/utils/auth";
import styles from "./AdminPanelAuth.module.scss";

const errorMessages = {
  loginRequired: "Please enter your login",
  passwordRequired: "Please enter your password",
};

const AdminPanelAuth = () => {
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [, authenticate] = useAuth();

  const onSubmit = useCallback((data: FieldValues) => {
    const { login, password } = data;
    const isSuccess = authenticate(login, password);
    if (isSuccess) {
      return;
    }

    setError("login", {
      message: "Wrong login or password",
    });
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.titleContainer}>
            <h1>Authorized users only</h1>
          </div>
          <form className={styles.form}>
            <div className={styles.inputField}>
              <Controller
                name="login"
                control={control}
                rules={{ required: true }}
                render={({ field: { value } }) => (
                  <Input
                    value={value}
                    onChange={(event) => {
                      setValue("login", event.target.value);
                      clearErrors("login");
                    }}
                    placeholder="Your login"
                  ></Input>
                )}
              />
              {errors.login && (
                <p className={styles.errorMessage}>
                  {String(errors.login.message) ?? errorMessages.loginRequired}
                </p>
              )}
            </div>

            <div className={styles.inputField}>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value } }) => (
                  <Input
                    value={value}
                    onChange={(event) => {
                      setValue("password", event.target.value);
                      clearErrors("password");
                    }}
                    placeholder="Your password"
                    type="password"
                  ></Input>
                )}
              />
              {errors.password && (
                <p className={styles.errorMessage}>
                  {String(errors.password.message) ??
                    errorMessages.passwordRequired}
                </p>
              )}
            </div>

            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className={styles.submitButton}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AdminPanelAuth;
