import React, { FC, memo } from "react";

import { IconProps } from "../types";

const ShareIcon: FC<IconProps> = ({ color = "" }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill={color || "currentColor"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9756 10.0456C10.5303 10.0456 10.1193 10.2019 9.79746 10.4628L6.55996 8.12062C6.61417 7.82307 6.61417 7.51818 6.55996 7.22062L9.79746 4.87844C10.1193 5.13937 10.5303 5.29562 10.9756 5.29562C12.01 5.29562 12.8506 4.455 12.8506 3.42062C12.8506 2.38625 12.01 1.54562 10.9756 1.54562C9.94121 1.54562 9.10059 2.38625 9.10059 3.42062C9.10059 3.60187 9.12559 3.77531 9.17402 3.94094L6.09902 6.1675C5.64277 5.56281 4.91777 5.17062 4.10059 5.17062C2.71934 5.17062 1.60059 6.28937 1.60059 7.67062C1.60059 9.05187 2.71934 10.1706 4.10059 10.1706C4.91777 10.1706 5.64277 9.77844 6.09902 9.17375L9.17402 11.4003C9.12559 11.5659 9.10059 11.7409 9.10059 11.9206C9.10059 12.955 9.94121 13.7956 10.9756 13.7956C12.01 13.7956 12.8506 12.955 12.8506 11.9206C12.8506 10.8862 12.01 10.0456 10.9756 10.0456ZM10.9756 2.60812C11.424 2.60812 11.7881 2.97219 11.7881 3.42062C11.7881 3.86906 11.424 4.23312 10.9756 4.23312C10.5271 4.23312 10.1631 3.86906 10.1631 3.42062C10.1631 2.97219 10.5271 2.60812 10.9756 2.60812ZM4.10059 9.04562C3.34277 9.04562 2.72559 8.42844 2.72559 7.67062C2.72559 6.91281 3.34277 6.29562 4.10059 6.29562C4.8584 6.29562 5.47559 6.91281 5.47559 7.67062C5.47559 8.42844 4.8584 9.04562 4.10059 9.04562ZM10.9756 12.7331C10.5271 12.7331 10.1631 12.3691 10.1631 11.9206C10.1631 11.4722 10.5271 11.1081 10.9756 11.1081C11.424 11.1081 11.7881 11.4722 11.7881 11.9206C11.7881 12.3691 11.424 12.7331 10.9756 12.7331Z"
        fill={color || "currentColor"}
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default memo(ShareIcon);
