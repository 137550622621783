import { InboxOutlined } from "@ant-design/icons";
import { Button, List, message, Upload, UploadProps } from "antd";
import PageLoader from "components/ui/PageLoader/PageLoader";
import AdminLayout from "containers/AdminLayout/AdminLayout";
import { useGoogleScript } from "hooks/useGoogleScript";
import { AppRoutesEnum } from "lib/constants/global";
import { UploadedLocation } from "lib/models/locations";
import { LocationsService } from "lib/services/locations/locations.service";
import { parseCSVFile } from "lib/utils/parser";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getOccasions } from "../../api/occasions";
import { Occasion } from "../../lib/models";
import styles from "./AdminPanelUpload.module.scss";
import UploadTable from "./UploadTable";

const { Dragger } = Upload;

const AdminPanel = () => {
  const { isLoaded } = useGoogleScript();
  const [loadedData, setLoadedData] = useState<Array<UploadedLocation>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [occasions, setOccasions] = useState<Occasion[]>([]);

  useEffect(() => {
    void getOccasions().then((querySnapshot) => {
      const fetchedOccasions = querySnapshot.docs.map(
        (document_) => document_.data() as Occasion,
      );
      setOccasions(fetchedOccasions);
    });
  }, []);

  const navigate = useNavigate();

  const dropDownProps: UploadProps = {
    name: "file",
    accept: ".csv",
    multiple: false,
    fileList: [],
    onDownload: (file) => {
      console.log("onDownload", file);
    },
    customRequest: ({ file, onSuccess, onError }) => {
      parseCSVFile(new File([file], "locations.csv"))
        .then((values) => {
          console.log("values", values);

          const uploadedLocations = values as Array<UploadedLocation>;
          setLoadedData(uploadedLocations);
          setErrorMessages([]);
          onSuccess && onSuccess(file);
        })
        .catch((error) => {
          onError && onError(error);
        });
    },
    onChange(info) {
      console.log("info", info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        void message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        void message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(element) {
      console.log("Dropped files", element.dataTransfer.files);
    },
    onRemove(file) {
      console.log("onRemove", file);
      setLoadedData([]);
    },
  };

  const onDownloadLocations = async () => {
    if (loadedData.length === 0 || !isLoaded) return;

    try {
      setIsLoading(true);
      const locationService = new LocationsService();
      const { errors } = await locationService.uploadNewLocationsToFirebase(
        loadedData,
        occasions,
      );

      if (errors.length === 0) {
        void message.success("Locations uploaded successfully 🌟");
        navigate(AppRoutesEnum.AdminPanelLocations);
        return;
      }

      setErrorMessages(errors);

      void message.warning(
        "The list of locations is not fully loaded, the list of errors is below",
      );
    } catch (error) {
      console.error(error);
      void message.error(`Error while uploading locations to firebase`);
    } finally {
      setLoadedData([]);
      setIsLoading(false);
    }
  };

  return (
    <AdminLayout>
      {isLoading && <PageLoader />}

      {/* Dragger */}
      <div className={styles.layout}>
        <Dragger {...dropDownProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>

        {/* Error List */}
        {errorMessages.length > 0 && (
          <List
            header={
              <strong>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Some locations from uploaded list is not valid and didn't
                uploaded to Firebase
              </strong>
            }
            bordered
            dataSource={errorMessages}
            renderItem={(item) => (
              <List.Item>
                <span className={styles.errorMessage}>{item}</span>
              </List.Item>
            )}
          />
        )}

        {/* Upload Table */}
        <div className={styles.tableWrapper}>
          <UploadTable data={loadedData} />
          <div className={styles.buttonWrapper}>
            {loadedData?.length > 0 && (
              <Button type="primary" onClick={onDownloadLocations}>
                UPLOAD LOCATIONS TO FIREBASE
              </Button>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPanel;
