import logoIcon from "assets/icons/logoWithText.svg";
import React, { memo } from "react";

import styles from "./Logo.module.scss";

const Logo = () => (
  <img src={logoIcon} className={styles.logo} alt="logo"></img>
);

export default memo(Logo);
