import { Button, Input, Table } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { getOccasions } from "../../../api/occasions";
import { Occasion } from "../../../lib/models";
import { Location } from "../../../lib/models/location";
import styles from "./LocationsTable.module.scss";

type UploadTableProps = {
  data: Array<Location>;
  onRemoveItem: (placeId: string) => void;
};

const UploadTable: React.FC<UploadTableProps> = ({ data, onRemoveItem }) => {
  const [loadedLocations, setLoadedLocations] = useState<Location[]>(data);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>(data);
  const [occasions, setOccasions] = useState<Occasion[]>([]);

  useEffect(() => {
    void getOccasions().then((querySnapshot) => {
      const fetchedOccasions = querySnapshot.docs.map(
        (document_) => document_.data() as Occasion,
      );
      setOccasions(fetchedOccasions);
    });
  }, []);

  const onSearch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (!event) return;

      const { value } = event.target ?? {};

      if (!value) return setFilteredLocations(loadedLocations);

      const searchValue = value.toLowerCase();

      const searchedLocations = loadedLocations.filter((location) =>
        location.name.toLowerCase().includes(searchValue),
      );

      setFilteredLocations(searchedLocations);
    },
    [loadedLocations],
  );

  const columns = useMemo(
    (): ColumnType<Location>[] => [
      {
        title: "Restaurant Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Address",
        dataIndex: "formatted_address",
        key: "formatted_address",
      },
      {
        title: "Categories",
        dataIndex: "categories",
        key: "categories",
        render: (categories: string[]) => {
          return (
            <div>
              {categories.map((category) => (
                <div key={category}>
                  {occasions.find((occasion) => occasion.type === category)
                    ?.name || "???"}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
      },
      {
        title: "Place ID",
        dataIndex: "place_id",
        key: "place_id",
      },
      {
        title: "",
        dataIndex: "delete",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (_: any, record: Location) => {
          return (
            <Button
              type="primary"
              onClick={async () => {
                if (!record.place_id) return null;

                await onRemoveItem(record.place_id);

                setFilteredLocations((previous) =>
                  previous.filter(
                    (location) => location.place_id !== record.place_id,
                  ),
                );
                setLoadedLocations((previous) =>
                  previous.filter(
                    (location) => location.place_id !== record.place_id,
                  ),
                );
              }}
            >
              Delete
            </Button>
          );
        },
      },
    ],
    [onRemoveItem, occasions],
  );

  useEffect(() => {
    setLoadedLocations(data);
    setFilteredLocations(data);
  }, [data]);

  return (
    <div style={{ display: "grid", marginTop: "40px" }}>
      <div>
        <h2>Stored locations</h2>
      </div>
      <div className={styles.searchContainer}>
        <Input placeholder="Search" onChange={onSearch} />
      </div>
      <Table showHeader columns={columns} dataSource={filteredLocations} />
    </div>
  );
};

export default UploadTable;
