import { uploadLocations } from "api/locations";
import { geohashForLocation } from "geofire-common";

import { Occasion } from "../../models";
import { Location } from "../../models/location";
import { GooglePlaceLocation, UploadedLocation } from "../../models/locations";

export class LocationsService {
  requestedFields = [
    "name",
    "formatted_address",
    "geometry",
    "website",
    "name",
    "formatted_address",
    "international_phone_number",
    "vicinity",
    "user_ratings_total",
    "business_status",
    "editorial_summary",
    "price_level",
    "rating",
    "types",
    "place_id",
    "url",
  ];

  private readonly googlePlaceService: google.maps.places.PlacesService;

  constructor() {
    this.googlePlaceService = new google.maps.places.PlacesService(
      document.createElement("div"),
    );
  }

  async uploadNewLocationsToFirebase(
    uploadedLocations: UploadedLocation[],
    occasions: Occasion[],
  ) {
    // Store the uploaded locations in a map for easy lookup
    const locationsMap = new Map<
      string,
      Pick<GooglePlaceLocation, "category" | "priority">
    >();
    for (const item of uploadedLocations) {
      locationsMap.set(item["Place ID"], {
        category: item["Category"],
        priority: Number(item["Priority"]),
      });
    }

    // Fetch places from Google
    const place_ids = [...locationsMap.keys()];
    const { values: googleLocations, errors } =
      await this.getPlacesList(place_ids);

    if (!googleLocations?.length)
      return {
        errors,
      };

    // Merge with our uploaded locations
    const locations = googleLocations
      .filter((location) => location && location.place_id)
      .map((location): GooglePlaceLocation => {
        if (!location!.place_id) {
          throw new Error(`Missing place_id for ${location!.name}`);
        }

        const extraFieldsForLocation = locationsMap.get(location!.place_id);

        if (!extraFieldsForLocation) {
          throw new Error(
            `Missing extraFieldsForLocation for ${location!.place_id}`,
          );
        }

        return {
          ...location,
          ...extraFieldsForLocation,
        };
      });

    // Parse the locations to our internal format
    const parsedLocations: Location[] = locations.map((location): Location => {
      // Map categories into internal format
      const categories = (location.category ?? "")
        .split(",")
        .map((item) => item.trim())
        .map((item) => {
          const existsOccasion = occasions.find(
            (occasion) => occasion.name === item,
          );

          if (!existsOccasion) {
            throw new Error(
              `Missing occasion for ${JSON.stringify(item, null, 2)}`,
            );
          }

          return existsOccasion.type;
        });

      // Prepare geometry
      const geometry: google.maps.LatLngLiteral = {
        lat: location.geometry?.location?.lat() ?? 0,
        lng: location.geometry?.location?.lng() ?? 0,
      };

      // Compute geohash
      const geohash = geohashForLocation([geometry.lat, geometry.lng]);

      return {
        is_recommended: true,
        business_status: location.business_status ?? "",
        categories,
        formatted_address: location.formatted_address ?? "",
        geohash,
        geometry,
        html_attributions: location.html_attributions ?? [],
        international_phone_number: location.international_phone_number ?? "",
        name: location.name ?? "Unnamed",
        place_id: location.place_id ?? "",
        price_level: location.price_level ?? 0,
        priority: location.priority ?? 1,
        rating: location.rating ?? 0,
        types: location.types ?? [],
        url: location.url ?? "",
        user_ratings_total: location.user_ratings_total ?? 0,
        vicinity: location.vicinity ?? "",
        website: location.website ?? "",
      };
    });

    await uploadLocations(parsedLocations);

    return {
      errors,
    };
  }

  private async getPlaceDetails(
    placeId: string,
  ): Promise<google.maps.places.PlaceResult> {
    const request = {
      placeId,
      fields: this.requestedFields,
    };
    return new Promise((resolve, reject) => {
      this.googlePlaceService.getDetails(request, (place, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !place) {
          console.error("Place not found");
          reject(`${placeId} - ${status}`);
          return;
        }

        resolve(place);
      });
    });
  }

  private async getPlacesList(place_ids: Array<string>): Promise<{
    values: google.maps.places.PlaceResult[];
    errors: string[];
  }> {
    const placePromises = place_ids.map(async (place_id: string) =>
      this.getPlaceDetails(place_id),
    );

    return Promise.allSettled(placePromises).then((results) => {
      const isFulfilled = <T>(
        p: PromiseSettledResult<T>,
      ): p is PromiseFulfilledResult<T> => p.status === "fulfilled";
      const isRejected = <T>(
        p: PromiseSettledResult<T>,
      ): p is PromiseRejectedResult => p.status === "rejected";

      const fulfilledValues = results.filter(isFulfilled).map((p) => p.value);
      const rejectedReasons = results.filter(isRejected).map((p) => p.reason);

      return { values: fulfilledValues, errors: rejectedReasons };
    });
  }
}
