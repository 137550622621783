import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import AdminLayout from "containers/AdminLayout/AdminLayout";
import { deleteDoc, doc, writeBatch } from "firebase/firestore";
import React, { ChangeEvent, memo, useEffect, useState } from "react";

import { getOccasions } from "../../api/occasions";
import { FirebaseCollectionEnum } from "../../lib/constants/firebase";
import { Occasion } from "../../lib/models";
import { db as database } from "../../lib/services/firebase";

const AdminPanelImportExport = () => {
  const [occasions, setOccasions] = useState<string>("");

  useEffect(() => {
    void getOccasions().then((querySnapshot) => {
      const fetchedOccasions = querySnapshot.docs.map(
        (document_) =>
          ({
            ...document_.data(),
            id: document_.id,
          }) as Occasion,
      );
      setOccasions(JSON.stringify(fetchedOccasions, null, 2));
    });
  }, []);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setOccasions(event.target.value);
  };

  return (
    <AdminLayout>
      <Button
        type="primary"
        onClick={async () => {
          const parsedOccasions = JSON.parse(occasions) as Occasion[];

          const documents = await getOccasions();
          await Promise.all(
            documents.docs.map(async (document_) => deleteDoc(document_.ref)),
          );

          const batch = writeBatch(database);

          for (const occasion of parsedOccasions) {
            const ref = doc(
              database,
              FirebaseCollectionEnum.OCCASIONS,
              occasion.id,
            );
            batch.set(ref, occasion);
          }

          // Commit the batch
          await batch.commit();
        }}
      >
        Upload occasions
      </Button>
      <TextArea value={occasions} onChange={handleChange}></TextArea>
    </AdminLayout>
  );
};

export default memo(AdminPanelImportExport);
