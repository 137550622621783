import crossIcon from "assets/icons/cross.svg";
import logoIcon from "assets/icons/logoWithText.svg";
import React, { memo, useCallback, useState } from "react";

import styles from "./SharedLinkModal.module.scss";

const SharedLinkModal = () => {
  const [showModal, setShowModal] = useState<boolean>(true);

  const handleOnClose = useCallback(() => setShowModal(false), []);

  return showModal ? (
    <div className={styles.container}>
      <img src={logoIcon} className={styles.logo} alt="logo" />
      <div className={styles.title}>You’ve been invited to meet Halfway!</div>
      <div className={styles.description}>
        Organizer selected this location for the occasion. <br />
        Get directions in your preferred map app!
      </div>
      <div>
        <button className={styles.closeButton} onClick={handleOnClose}>
          <img src={crossIcon} alt="close" />
        </button>
      </div>
    </div>
  ) : null;
};

export default memo(SharedLinkModal);
