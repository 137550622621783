import { message } from "antd";
import clsx from "clsx";
import DirectionIcon from "components/icons/Direction";
import ShareIcon from "components/icons/Share";
import { SHARED_SEARCH_QUERY } from "lib/constants/global";
import { buildGoogleDirectionUrl } from "lib/utils/google";
import { FC, memo, useCallback, useEffect, useReducer } from "react";
import ReactGA from "react-ga4";

import { Location } from "../../lib/models/location";
import LocationCard from "../LocationCard/LocationCard";
import styles from "./LocationDetailsCard.module.scss";

export type LocationDetailsCardProps = {
  halfwayId: string;
  locationInfo: Location | null;
  isShared?: boolean;
  onClose?: () => void;
};

const LocationDetailsCard: FC<LocationDetailsCardProps> = ({
  locationInfo,
  halfwayId,
  isShared,
  onClose,
}) => {
  const [locationDetails, setLocationDetails] = useReducer(
    (previousData: Location | null, newData: Location | null) => {
      if (
        newData &&
        !newData.url &&
        (!previousData || previousData.place_id !== newData.place_id)
      ) {
        console.log(`need to fetch details for ${newData.name}`);
      }

      return newData;
    },
    locationInfo,
  );
  useEffect(() => {
    setLocationDetails(locationInfo);
  }, [locationInfo]);

  const onBuildDirection = useCallback(() => {
    if (!locationInfo) {
      return message.error("Location not found");
    }

    window.open(
      buildGoogleDirectionUrl(
        {
          lat: locationInfo.geometry.lat,
          lng: locationInfo.geometry.lng,
        },
        locationInfo.place_id,
      ),
      "_blank",
    );
  }, [locationInfo]);

  const onShare = useCallback(async () => {
    ReactGA.event({
      category: "ShareLocation",
      action: "share_location_click",
      label: "Share button",
    });

    await navigator.clipboard.writeText(
      window.location.origin +
        `/halfway/${halfwayId}/location/${locationInfo?.place_id}/?${SHARED_SEARCH_QUERY}=true`,
    );
    await message.success("Link copied to clipboard");
  }, [halfwayId, locationInfo?.place_id]);

  if (!locationDetails) return null;

  return (
    <div className={styles.container}>
      <LocationCard
        {...locationDetails}
        is_recommended={true}
        isClosable={!isShared}
        detailView
        onClose={onClose}
        tags={locationDetails.types?.map((type) => ({ name: type }))}
      />
      <div className={styles.toolsContainer}>
        {isShared ? (
          <button
            className={clsx(styles.primaryButton)}
            onClick={onBuildDirection}
          >
            <DirectionIcon color="white" />
            Directions
          </button>
        ) : (
          <>
            <a
              href="https://www.opentable.com/"
              target="_blank"
              className={styles.primaryButton}
              rel="noreferrer"
            >
              Reservations
            </a>
            <div className={styles.shareButtonContainer}>
              <button
                className={clsx(styles.shareButton, styles.secondaryButton)}
                onClick={onShare}
              >
                <ShareIcon color="white" />
                <span>Share</span>
              </button>
              <button
                className={clsx(styles.directionButton, styles.secondaryButton)}
                onClick={onBuildDirection}
              >
                <DirectionIcon color="#69933a" />
                Directions
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(LocationDetailsCard);
