import noResultsIcon from "assets/icons/no-results.svg";
import LocationCard from "components/LocationCard/LocationCard";
import { FC, Fragment, PropsWithChildren } from "react";

import { Location } from "../../lib/models/location";
import styles from "./LocationList.module.scss";

type LocationListProps = {
  locations: Location[];
  activeLocation?: string;
  onLocationClick?: (place_id: string) => void;
};

const LocationList: FC<PropsWithChildren<LocationListProps>> = ({
  locations,
  activeLocation,
  children,
  onLocationClick,
}) => {
  return (
    <div className={styles.container}>
      {locations.length > 0 && (
        <Fragment>
          {locations.map((location) => (
            <LocationCard
              {...location}
              onClick={onLocationClick}
              tags={location.types?.map((type) => ({ name: type }))}
              isActive={activeLocation === location.place_id}
              key={location.place_id}
            />
          ))}
          <div>{children}</div>
        </Fragment>
      )}
      {locations.length === 0 && (
        <div className={styles.loading}>
          <div className={styles.noResults}>
            <img src={noResultsIcon} alt="no-results" />
            No suitable locations found
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationList;
