import soonIcon from "assets/images/soon.jpeg";
import React, { memo } from "react";

import styles from "./ComingSoon.module.scss";

const ComingSoon = () => {
  return (
    <div className={styles.container}>
      <img src={soonIcon} className={styles.image} alt="Coming soon" />
    </div>
  );
};

export default memo(ComingSoon);
