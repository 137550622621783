import "@react-google-maps/api";
import "assets/styles/main.scss";

import { ConfigProvider } from "antd";
import { getUserGAKey } from "lib/utils/app";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || "", {
  gaOptions: {
    userId: getUserGAKey(),
  },
});

const root = ReactDOM.createRoot(
  document.querySelector("#root") as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <BrowserRouter window={window}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Roboto, sans-serif",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </BrowserRouter>,

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
