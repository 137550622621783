import { FOOTER_LINKS, SOCIAL_MEDIA_LINKS } from "lib/constants/footer";
import { Link } from "react-router-dom";

import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.socialMediaContainer}>
        {SOCIAL_MEDIA_LINKS.map((link) => (
          <div key={link.url}>
            <a href={link.url}>
              <img src={link.icon} alt={link.alt} />
            </a>
          </div>
        ))}
      </div>
      <ul className={styles.internalLinks}>
        {FOOTER_LINKS.map((link) => (
          <li key={link.url}>
            <Link to={link.url} className={styles.link}>
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
