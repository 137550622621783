import React, { FC, PropsWithChildren } from "react";

import Footer from "./components/Footer/Footer";
import Logo from "./components/Logo/Logo";
import styles from "./Layout.module.scss";

type LayoutProps = PropsWithChildren<{
  withFooter?: boolean;
}>;

const Layout: FC<LayoutProps> = ({ children, withFooter = true }) => {
  return (
    <div className={styles.layout}>
      <div>
        <div className={styles.logoContainer}>
          <Logo></Logo>
          <div className={styles.logoBetaText}>BETA</div>
        </div>
        <main>{children}</main>
      </div>

      {withFooter && <Footer />}
    </div>
  );
};

export default Layout;
