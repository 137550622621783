import { GoogleMap as Map } from "@react-google-maps/api";
import { Spin } from "antd";
import clsx from "clsx";
import { useGoogleScript } from "hooks/useGoogleScript";
import { DEFAULT_MAP_OPTS, DEFAULT_ZOOM_LEVEL } from "lib/constants/google";
import React, { FC, PropsWithChildren } from "react";

import styles from "./GoogleMap.module.scss";

type GoogleMapProps = PropsWithChildren<{
  coordinates?: google.maps.LatLngLiteral;
  containerClassName?: string;
  mapClassName?: string;
  options?: google.maps.MapOptions;
  zoom?: number;
  handleOnIdle?: () => void;
  handleOnLoad?: (map: google.maps.Map) => void;
  onDragEnd?: () => void;
}>;

const GoogleMap: FC<GoogleMapProps> = ({
  coordinates,
  containerClassName,
  mapClassName,
  options,
  zoom = DEFAULT_ZOOM_LEVEL,
  children,
  handleOnIdle,
  handleOnLoad,
  onDragEnd,
}) => {
  const { isLoaded } = useGoogleScript();

  const options_ = { ...DEFAULT_MAP_OPTS, ...options };

  return (
    <div className={containerClassName}>
      {isLoaded ? (
        <Map
          mapContainerClassName={mapClassName ?? styles.googleMapContainer}
          center={coordinates}
          options={options_}
          zoom={zoom}
          onIdle={handleOnIdle}
          onLoad={handleOnLoad}
          onDragEnd={onDragEnd}
        >
          {children}
        </Map>
      ) : (
        <div className={clsx(styles.googleMapContainer, styles.loader)}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default GoogleMap;
