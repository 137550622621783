import { message, Spin } from "antd";
import arrowLeftIcon from "assets/icons/arrow-left.svg";
import googlePinRecommendedIcon from "assets/icons/google-pin-recommended.svg";
import googlePinTrivialIcon from "assets/icons/google-pin-trivial.svg";
import ShareIcon from "components/icons/Share";
import LocationDetailsCard from "components/LocationDetailsCard/LocationDetailsCard";
import LocationList from "components/LocationList/LocationList";
import SharedLinkModal from "components/SharedLinkModal/SharedLinkModal";
import MapLayout from "containers/MapLayout/MapLayout";
import { useFetchHalfway } from "hooks/useFetchHalfway";
import { AppRoutesEnum, SHARED_SEARCH_QUERY } from "lib/constants/global";
import { HalfwayService } from "lib/services/halfway/halfway.service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Location } from "../../lib/models/location";
import styles from "./HalfwayResult.module.scss";

const HalfwayResult = () => {
  const { halfwayId } = useParams<{ halfwayId: string }>();
  const navigate = useNavigate();
  const { fetchHalfway } = useFetchHalfway();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [activeLocation, setActiveLocation] = useState<string>("");
  const [locationDetailsId, setLocationDetailsId] = useState<string>("");
  const [locationDetails, setLocationDetails] = useState<Location | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [nearLocation, setNearLocation] = useState<Location[]>([]);

  const [searchParameters] = useSearchParams();

  const isShared = useMemo(
    () => searchParameters.get(SHARED_SEARCH_QUERY) === "true",
    [searchParameters],
  );

  const markerViews = useRef<google.maps.InfoWindow[]>([]);
  const markers = useRef<google.maps.Marker[]>([]);
  const googleHelperRef = useRef<HalfwayService | null>(null);

  const onLoad = useCallback((map: google.maps.Map | null) => {
    setMap(map);
  }, []);

  //Remove after custom location
  const handleSearchResults = useCallback(
    (
      results: Location[] | null,
      status: google.maps.places.PlacesServiceStatus,
    ) => {
      if (!results) {
        setLoading(false);
        return;
      }

      if (status !== google.maps.places.PlacesServiceStatus.OK) return;
      setNearLocation(results);

      for (const place of results) {
        const infoWindow = new google.maps.InfoWindow({
          content: place.vicinity,
        });
        const marker = new google.maps.Marker({
          position: { lat: place.geometry.lat, lng: place.geometry.lng },
          clickable: true,
          title: place.vicinity,
          icon: place.is_recommended
            ? googlePinRecommendedIcon
            : googlePinTrivialIcon,
        });

        marker.set("place_id", place.place_id);
        infoWindow.set("place_id", place.place_id);
        marker.setMap(map);

        marker.addListener("click", () => {
          for (const markerInfoView of markerViews.current) {
            markerInfoView.close();
          }

          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: true,
          });

          map?.setZoom(15);
          map?.panTo(marker.getPosition()!);

          // eslint-disable-next-line unicorn/prefer-query-selector
          const location = document.getElementById("location" + place.place_id);
          setActiveLocation(place.place_id || "");
          location?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        });

        markerViews.current.push(infoWindow);
        markers.current.push(marker);
      }

      setLoading(false);
    },
    [map],
  );

  const onLocationClick = useCallback(
    (id: string) => {
      setLocationDetailsId(id);

      // Hide all markers except the one clicked
      for (const marker of markers.current) {
        if (marker.get("place_id") === id) {
          map?.panTo(marker.getPosition()!);
        } else {
          marker.setVisible(false);
        }
      }

      // Hide all markers except the one clicked
      for (const markerInfoView of markerViews.current) {
        if (markerInfoView.get("place_id") !== id) {
          markerInfoView.close();
        }
      }
    },
    [map],
  );

  const onCloseLocation = useCallback(() => {
    // When returning back to locations list, scroll to the last opened location
    // (do it in timeout to wait when list are rendered, and after we can find interested place by its id)
    const lastOpenedLocation = locationDetailsId;
    setTimeout(() => {
      // eslint-disable-next-line unicorn/prefer-query-selector
      const location = document.getElementById("location" + lastOpenedLocation);
      setActiveLocation(lastOpenedLocation);
      location?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }, 0);

    setLocationDetailsId("");
    for (const marker of markers.current) {
      marker.setVisible(true);
    }

    for (const markerInfoView of markerViews.current) {
      markerInfoView.close();
    }

    googleHelperRef.current?.zoomToHalfwayPoint();
  }, [locationDetailsId]);

  const onBackButtonClick = useCallback(() => {
    locationDetailsId ? onCloseLocation() : navigate(AppRoutesEnum.ROOT);
  }, [locationDetailsId, navigate, onCloseLocation]);

  const onShareLocation = useCallback(async () => {
    try {
      ReactGA.event({
        category: "ShareList",
        action: "share_suggested_locations_click",
        label: "Share Suggested Locations",
      });
      await navigator.clipboard.writeText(
        window.location.origin +
          `/halfway/${halfwayId}/?${SHARED_SEARCH_QUERY}=true`,
      );
      await message.success("Link copied to clipboard");
    } catch (error) {
      console.error(error);
    }
  }, [halfwayId]);

  const toolsButtons = useMemo(() => {
    if (loading) return <Spin />;

    return isShared ? null : (
      <button className={styles.shareButton} onClick={onShareLocation}>
        <ShareIcon color="white" />
        Share Suggested Locations
      </button>
    );
  }, [isShared, loading, onShareLocation]);

  const header = useMemo(
    () =>
      isShared ? (
        <SharedLinkModal />
      ) : (
        <div className={styles.backButton} onClick={onBackButtonClick}>
          <img src={arrowLeftIcon} alt="arrow-left" />
          <span className={styles.backButtonText}>Back</span>
        </div>
      ),
    [isShared, onBackButtonClick],
  );

  useEffect(() => {
    if (!map || !halfwayId) return;

    const showNearestPlaces = async () => {
      const halfway = await fetchHalfway(halfwayId);

      const halfwayService = new HalfwayService(
        map,
        halfway.address1,
        halfway.address2,
        halfway.occasion,
      );

      await halfwayService.calculateHalfwayPointWithTrafficOffset();
      halfwayService.drawSearchAreaCircle();
      halfwayService.searchNearestPlaces(handleSearchResults);

      //Move to context
      googleHelperRef.current = halfwayService;
    };

    showNearestPlaces().catch((error) => {
      console.error(error);
    });
  }, [handleSearchResults, halfwayId, map, navigate, fetchHalfway]);

  useEffect(() => {
    if (!map || !halfwayId) {
      return;
    }

    if (!locationDetailsId) {
      if (locationDetails) {
        setLocationDetails(null);
      }
      return;
    }

    if (
      locationDetailsId &&
      locationDetails &&
      locationDetails.place_id === locationDetailsId
    ) {
      return;
    }

    const fetchLocationDetails = async () => {
      const halfway = await fetchHalfway(halfwayId);

      const halfwayService = new HalfwayService(
        map,
        halfway.address1,
        halfway.address2,
        halfway.occasion,
      );

      const details = await halfwayService.getPlaceDetails(locationDetailsId);

      if (details.place_id === locationDetailsId) {
        setLocationDetails(details);
      }
    };

    void fetchLocationDetails().catch((error) => console.error(error));
  }, [locationDetailsId, halfwayId, map, locationDetails, fetchHalfway]);

  return (
    <MapLayout onLoad={onLoad} header={header}>
      {loading && (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
      {!loading && (
        <div>
          {locationDetailsId ? (
            <LocationDetailsCard
              onClose={onCloseLocation}
              halfwayId={halfwayId ?? ""}
              locationInfo={
                locationDetails ??
                nearLocation.find(
                  (location) => location.place_id === locationDetailsId,
                ) ??
                null
              }
            />
          ) : (
            <LocationList
              activeLocation={activeLocation}
              locations={nearLocation}
              onLocationClick={onLocationClick}
            >
              {toolsButtons}
            </LocationList>
          )}
        </div>
      )}
    </MapLayout>
  );
};

export default HalfwayResult;
