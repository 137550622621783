import Papa from "papaparse";

export const parseCSVFile = async (file: File) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      complete: function (results: { data: unknown[] }) {
        console.log(results.data);
        resolve(results.data);
      },
      error: function (error) {
        reject(error);
      },
    });
  });
