/**
 * Implemets extract specific fields from Place object of google autocomplete field
 * @param { google.maps.places.PlaceResult } place Place object of Autocomplete field
 * @returns { GooglePlaceAddress } Object with mapped field
 */

import { AddressObject } from "lib/models";

export const mapPlaceToAddress = (
  place?: google.maps.places.PlaceResult,
): string => {
  const { formatted_address, name } = place || {};

  const namePart = name ? `${name},` : "";

  return `${namePart} ${formatted_address ?? ""}`;
};

export const transformPlaceGeolocation = (
  place?: google.maps.places.PlaceResult,
): AddressObject => {
  const { geometry, ...rest } = place || {};

  return {
    ...rest,
    coordinates: geometry?.location?.toJSON(),
  };
};

export type GoogleLocation =
  | google.maps.LatLng
  | google.maps.LatLngLiteral
  | null
  | undefined;
export type GoogleAddress = string | null | undefined;

export const asyncGeocode = async (options: {
  address?: GoogleAddress;
  location?: GoogleLocation;
}): Promise<google.maps.places.PlaceResult[] | null> => {
  return new Promise((resolve, reject) => {
    void new google.maps.Geocoder().geocode(options, (results, status) => {
      if (status === "OK") {
        resolve(results);
      } else {
        reject({ status, results });
      }
    });
  });
};

export const buildGoogleDirectionUrl = (
  location: google.maps.LatLngLiteral,
  placeId: string,
): string => {
  if (!location) return "";

  return `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}&destination_place_id=${placeId}&travelmode=driving`;
};

export const kilometersToMeters = (kilometers: number): number =>
  kilometers * 1000;

export const metersToKilometers = (meters: number): number => meters / 1000;
