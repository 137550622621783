import React from "react";

import Loader from "./Loader/Loader";
import styles from "./PageLoade.module.scss";

const PageLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    </div>
  );
};

export default PageLoader;
