import { Table } from "antd";
import React from "react";

type UploadTableProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>;
};

const columns = [
  {
    title: "Restaurant Name",
    dataIndex: "Restaurant Name",
    key: "name",
    width: "25%",
  },
  {
    title: "Address",
    dataIndex: "Address",
    key: "address",
  },
  {
    title: "Category",
    dataIndex: "Category",
    key: "category",
    width: "15%",
  },
  {
    title: "Priority",
    dataIndex: "Priority",
    key: "priority",
    width: "5%",
  },
  {
    title: "Place ID",
    dataIndex: "Place ID",
    key: "place_id",
  },
];

const UploadTable: React.FC<UploadTableProps> = ({ data }) => {
  if (data.length === 0) return null;

  return (
    <div
      style={{
        display: "grid",
        rowGap: "20px",
      }}
    >
      <div>
        <h2>Preview locations</h2>
      </div>
      <Table
        showHeader
        style={{ width: "70vw" }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record["Place ID"]}
      />
    </div>
  );
};

export default UploadTable;
