import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { FirebaseCollectionEnum } from "lib/constants/firebase";
import { Halfway } from "lib/models/halfway";
import { db } from "lib/services/firebase";

export const createHalfWay = async (payload: Halfway) =>
  addDoc(collection(db, FirebaseCollectionEnum.HALFWAY_RESULTS), payload);

export const getHalfWay = async (id: string) =>
  getDoc(doc(db, FirebaseCollectionEnum.HALFWAY_RESULTS, id));
