import { collection, getDocs, query, where } from "firebase/firestore";
import { FirebaseCollectionEnum } from "lib/constants/firebase";
import { db } from "lib/services/firebase";

import { Location } from "../../lib/models/location";

export const getLocation = async (
  placeId: string,
): Promise<Location | null> => {
  const locationsRef = collection(db, FirebaseCollectionEnum.LOCATIONS);
  const q = query(locationsRef, where("place_id", "==", placeId));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.docs.length === 0) {
    return null;
  }

  if (querySnapshot.docs.length > 1) {
    console.error(`Multiple locations found for placeId: ${placeId}`);
    return null;
  }

  return querySnapshot.docs[0].data() as Location;
};
