import { Select, Spin } from "antd";
import type { DefaultOptionType } from "antd/lib/select";
import { getOccasions } from "api/occasions";
import LocationSelector from "components/LocationSelector/LocationSelector";
import { useGoogleScript } from "hooks/useGoogleScript";
import { Occasion } from "lib/models";
import { disableScroll, enableScroll } from "lib/utils/dom";
import { FC, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Controller, useForm } from "react-hook-form";

import styles from "./MeetingForm.module.scss";

type MeetingFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void;
};

const errorMessages = {
  addressRequired: "Please specify an address",
  occasion: "Please select the occasion type",
  notValidAddress: "Please enter a valid address",
};

const MeetingForm: FC<MeetingFormProps> = ({ onSubmit }) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
  const { isLoaded } = useGoogleScript();

  const {
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getOptions = async () => {
      setIsOptionsLoading(true);
      try {
        const querySnapshot = await getOccasions();

        const options = querySnapshot.docs.map((document) => {
          const data = document.data() as Occasion;

          return {
            value: data.type,
            label: data.name,
          };
        });

        setOptions(options);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsOptionsLoading(false);
      }
    };

    void getOptions();
  }, []);

  if (!isLoaded)
    return (
      <div className={styles.form}>
        <Spin size="large" />
      </div>
    );
  return (
    <form className={styles.form}>
      <div className={styles.inputField}>
        <Controller
          name="address1"
          control={control}
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <LocationSelector
              onPlaceChanged={(place) => {
                setValue("address1", place);
                clearErrors("address1");
              }}
              onChange={() => {
                if (value) {
                  setError("address1", {
                    message: errorMessages.notValidAddress,
                  });
                }
              }}
              isLoaded={isLoaded}
              showLocationDetector
              error={!!errors.address1}
              placeholder="Your address"
            />
          )}
        />
        {errors.address1 && (
          <p className={styles.errorMessage}>
            {String(errors.address1.message) ?? errorMessages.addressRequired}
          </p>
        )}
      </div>

      <div className={styles.inputField}>
        <Controller
          name="address2"
          control={control}
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <LocationSelector
              onPlaceChanged={(place) => {
                setValue("address2", place);
                clearErrors("address2");
              }}
              onChange={() => {
                if (value) {
                  setError("address2", {
                    message: errorMessages.notValidAddress,
                  });
                }
              }}
              isLoaded={isLoaded}
              error={!!errors.address2}
              placeholder="Their Address"
            />
          )}
        />
        {errors.address2 && (
          <p className={styles.errorMessage}>
            {String(errors.address2.message) ?? errorMessages.addressRequired}
          </p>
        )}
      </div>

      <div className={styles.inputField}>
        <Controller
          name="occasion"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              loading={isOptionsLoading}
              size="large"
              status={errors.occasion ? "error" : undefined}
              onDropdownVisibleChange={(open) => {
                open ? disableScroll() : enableScroll();
              }}
              placeholder="Occasion Type"
              className={styles.select}
              {...field}
              onChange={(value) => {
                ReactGA.event({
                  category: "User",
                  action: "occasion_type_click",
                  label: value,
                });
                field.onChange(value);
              }}
              options={options}
            />
          )}
        />
        {errors.occasion && (
          <p className={styles.errorMessage}>{errorMessages.occasion}</p>
        )}
      </div>

      <button
        type="button"
        onClick={handleSubmit(onSubmit)}
        className={styles.submitButton}
      >
        Meet Me Halfway
      </button>
    </form>
  );
};

export default MeetingForm;
