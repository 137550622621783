import { Autocomplete } from "@react-google-maps/api";
import { Input, message, Spin } from "antd";
import locationPinIcon from "assets/icons/location-pin.svg";
import clsx from "clsx";
import { AUTOCOMPLETE_OPTIONS } from "lib/constants/google";
import { asyncGeocode, mapPlaceToAddress } from "lib/utils/google";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import styles from "./LocationSelector.module.scss";

type LocationSelectorProps = {
  isLoaded?: boolean;
  showLocationDetector?: boolean;
  error: boolean;
  onPlaceChanged: (autocomplete: google.maps.places.PlaceResult) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const LocationSelector: FC<LocationSelectorProps> = ({
  isLoaded,
  showLocationDetector,
  error,
  onPlaceChanged,
  ...inputProps
}) => {
  const inputRef = useRef<HTMLInputElement | null>();
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const getCurrentLocation = useCallback(() => {
    if (!navigator.geolocation) {
      // TODO: show error message
    }

    if (isLoading) return;

    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        if (lat && lng) {
          try {
            const results = await asyncGeocode({ location: { lat, lng } });

            if (results?.length && inputRef?.current) {
              inputRef.current.value = mapPlaceToAddress(results[0]);
              onPlaceChanged(results[0]);
            }
          } catch (error_) {
            console.log("err", error_);
            void messageApi.open({
              type: "error",
              content: "No address was found for your current position",
            });
          } finally {
            setIsLoading(false);
          }
        }

        setIsLoading(false);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          void messageApi.open({
            type: "error",
            content: "Please enable location services in your browser",
          });
        } else {
          void messageApi.open({
            type: "error",
            content: "Something went wrong while getting your location",
          });
        }

        setIsLoading(false);
      },
    );
  }, [isLoading, messageApi, onPlaceChanged]);

  const onLoad = useCallback(
    (googleAutoComplete: google.maps.places.Autocomplete) => {
      autocomplete.current = googleAutoComplete;
    },
    [],
  );

  const handleOnPlaceChanged = useCallback(() => {
    if (autocomplete != null && autocomplete.current) {
      const place = autocomplete.current.getPlace();

      onPlaceChanged(place);
    }
  }, [onPlaceChanged]);

  const locationIcon = useMemo(() => {
    if (!showLocationDetector) return null;

    return isLoading ? (
      <div className={styles.iconWrapper}>
        <Spin />
      </div>
    ) : (
      <div className={styles.iconWrapper} onClick={getCurrentLocation}>
        <img src={locationPinIcon} alt="get current location" />
      </div>
    );
  }, [getCurrentLocation, isLoading, showLocationDetector]);

  if (!isLoaded) {
    return <Spin />;
  }

  return (
    <>
      {/* Some hack to add antd input styles */}
      <Input style={{ display: "none" }} />
      {contextHolder}
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={handleOnPlaceChanged}
        options={AUTOCOMPLETE_OPTIONS}
      >
        <div className={styles.inputWrapper}>
          <input
            type="text"
            ref={inputRef as unknown as React.RefObject<HTMLInputElement>}
            className={clsx(
              styles.input,
              "ant-input",
              "css-dev-only-do-not-override-ed5zg0",
              "css-ed5zg0",
              {
                [styles.showLocationDetector]: showLocationDetector,
                "ant-input-status-error": error,
              },
            )}
            {...inputProps}
          />
          {locationIcon}
        </div>
      </Autocomplete>
    </>
  );
};

export default LocationSelector;
