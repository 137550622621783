import "@react-google-maps/api";

import { createHalfWay } from "api/halfway";
import Layout from "containers/MainLayout/Layout";
import { Halfway } from "lib/models/halfway";
import { transformPlaceGeolocation } from "lib/utils/google";
import { useCallback } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import styles from "./MeetingCreating.module.scss";
import MeetingForm from "./MeetingForm/MeetingForm";

const MeetingCreating = () => {
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (data: Halfway) => {
      const { address1, address2, occasion } = data;
      ReactGA.event({
        category: "MeetMe",
        action: "meet_me_click",
        label: "MeetingCreating",
      });

      const payload = {
        occasion,
        address1: transformPlaceGeolocation(address1),
        address2: transformPlaceGeolocation(address2),
      };

      createHalfWay(payload)
        .then((response) => {
          navigate(`/halfway/${response.id}`);
        })
        .catch((error) => {
          console.log("err", error);
        })
        .finally(() => {
          //stop loading
        });
    },
    [navigate],
  );

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.titleContainer}>
            <h1>
              Los Angeles is a sprawling city and life happens in every corner
              of the map. Find potential bars, restaurants & coffee shops
              halfway between you and your friends, colleagues or dates.
            </h1>
          </div>
          <MeetingForm onSubmit={onSubmit} />
        </div>
      </div>
    </Layout>
  );
};

export default MeetingCreating;
