import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import {
  distanceBetween,
  geohashForLocation,
  geohashQueryBounds,
  Geopoint,
} from "geofire-common";
import { FirebaseCollectionEnum } from "lib/constants/firebase";
import { AddressObject } from "lib/models/region";

import { getLocation } from "../../../api/locations/index.my";
import { Location } from "../../models/location";
import { kilometersToMeters } from "../../utils/google";
import { db as database } from "../firebase";
import { GoogleDirection } from "../googleDirection/googleDirection.service";

interface LocationWithDistance extends Location {
  distance: number;
}

type GoogleSearchResult = {
  results: google.maps.places.PlaceResult[] | null;
  status: google.maps.places.PlacesServiceStatus;
  c: google.maps.places.PlaceSearchPagination | null;
};

export class HalfwayService {
  halfWayPoint: google.maps.LatLng | google.maps.LatLngLiteral | null = null;
  searchAreaRadius: number = 0;

  // Requested fields from Google Places API
  requestedFields = [
    "place_id",
    "business_status",
    "formatted_address",
    "geometry",
    "name",
    "price_level",
    "rating",
    "types",
    "user_ratings_total",
    "website",
    "vicinity",
    "editorial_summary",
  ];

  //Magic numbers for adjust the search radius
  private readonly K = 1.2;
  private readonly P = 1.8;
  googlePlaceService: google.maps.places.PlacesService;
  googleDirectionService: GoogleDirection;

  constructor(
    private readonly map: google.maps.Map,
    private readonly address1: AddressObject,
    private readonly address2: AddressObject,
    private readonly occasion: string,
  ) {
    this.googlePlaceService = new google.maps.places.PlacesService(map);
    this.googleDirectionService = new GoogleDirection();
  }

  drawSearchAreaCircle = (): void => {
    if (!this.halfWayPoint) {
      console.error("Halfway point is not defined");
      return;
    }

    //Create a marker for each address
    const address1Marker = new google.maps.Marker({
      position: this.address1.coordinates,
      clickable: true,
    });
    const address2Marker = new google.maps.Marker({
      position: this.address2.coordinates,
      clickable: true,
    });

    address1Marker.setMap(this.map);
    address2Marker.setMap(this.map);

    const computedDistanceBetweenPoints = Math.max(
      1,
      google.maps.geometry.spherical.computeDistanceBetween(
        this.address1.coordinates!,
        this.address2.coordinates!,
      ),
    );

    this.searchAreaRadius = this.calculateSearchAreaRadius(
      computedDistanceBetweenPoints / 1000,
    );

    const circle = new google.maps.Circle({
      center: this.halfWayPoint,
      radius: this.searchAreaRadius * 1000,
      editable: false,
      draggable: false,

      fillColor: "#69933ad9",
      strokeColor: "#69933A",
    });

    circle.setMap(this.map);

    this.zoomToHalfwayPoint();
  };

  zoomToHalfwayPoint = (): void => {
    const circle = new google.maps.Circle({
      center: this.halfWayPoint,
      radius: this.searchAreaRadius * 1000,
    });

    const circleBounds = new google.maps.LatLngBounds();
    circleBounds.extend(this.halfWayPoint!);
    circleBounds.union(circle.getBounds()!);

    this.map.fitBounds(circleBounds);
  };

  async calculateHalfwayPointWithTrafficOffset(): Promise<google.maps.LatLng> {
    const point1 = this.address1;
    const point2 = this.address2;

    const halfWayPoint = this.calculateGeometryHalfwayPoint(point1, point2);
    this.halfWayPoint = halfWayPoint;

    // calculate the duration from each point to the halfway point
    const [location1Duration, location2Duration] =
      await this.googleDirectionService.calculateDurationFromLocationsToHalfwayPoint(
        point1,
        point2,
        halfWayPoint,
      );

    // find the location to which we need to move the halfway point
    const targetLocation =
      location1Duration > location2Duration ? point1 : point2;

    const timeDifference = Math.abs(location1Duration - location2Duration);

    const distanceOffset = this.googleDirectionService.calculateDistanceOffset(
      timeDifference / 60,
    );

    const heading = google.maps.geometry.spherical.computeHeading(
      halfWayPoint,
      targetLocation.coordinates!,
    );

    // the new halfway point with offset according to traffic
    const destination = google.maps.geometry.spherical.computeOffset(
      halfWayPoint,
      kilometersToMeters(distanceOffset),
      heading,
    );

    this.halfWayPoint = destination;

    return destination;
  }

  searchNearestPlaces = (
    callback: (
      results: Location[] | null,
      status: google.maps.places.PlacesServiceStatus,
    ) => void,
  ): void => {
    const findPlaces = async () => {
      if (!this.halfWayPoint) {
        console.error("Halfway point is not defined");
        return;
      }

      const point = this.halfWayPoint as google.maps.LatLng;
      const center: Geopoint = [point.lat(), point.lng()];

      const radiusInM = this.searchAreaRadius * 1000;

      const [fetchedLocations, googleLocationsResult] = await Promise.all([
        this.searchNearestPlacesFromDatabase(radiusInM, center),
        this.searchNearestPlacesFromGoogle(radiusInM),
      ]);
      const googleLocations =
        googleLocationsResult.results?.map(this.convertGooglePlaceToLocation) ||
        [];

      const locations = [
        ...fetchedLocations,
        ...googleLocations.filter(
          (googlePlace) =>
            !fetchedLocations.some(
              (fetchedLocation) =>
                fetchedLocation.place_id === googlePlace.place_id,
            ),
        ),
      ];

      const matchingDocuments: LocationWithDistance[] = [];
      for (const data of locations) {
        const lat = data.geometry.lat;
        const lng = data.geometry.lng;

        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = distanceBetween([lat, lng], center);
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radiusInM) {
          matchingDocuments.push({
            ...data,
            distance: distanceInKm,
          });
        }
      }

      matchingDocuments.sort((a, b) => a.distance - b.distance);
      matchingDocuments.sort((a, b) => a.priority - b.priority);
      matchingDocuments.sort((a, b) => (b.is_recommended ? 1 : -1));

      // console.log(JSON.stringify(matchingDocs.map(doc => doc), null, 2));

      callback(matchingDocuments, google.maps.places.PlacesServiceStatus.OK);
    };

    void findPlaces();
  };

  getPlaceDetails = async (placeId: string): Promise<Location> => {
    const place = await getLocation(placeId);

    if (!place) {
      const googlePlace = await this.getPlaceDetailsFromGoogle(placeId);

      if (!googlePlace) {
        throw new Error(`Place not found for placeId: ${placeId}`);
      }

      return this.convertGooglePlaceToLocation(googlePlace);
    }

    return place;
  };

  searchNearestPlacesFromDatabase = async (
    radiusInM: number,
    center: Geopoint,
  ): Promise<Location[]> => {
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    const locationsRef = collection(database, FirebaseCollectionEnum.LOCATIONS);
    for (const b of bounds) {
      const q = query(
        locationsRef,
        where("categories", "array-contains", this.occasion),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1]),
      );

      promises.push(getDocs(q));
    }

    const snapshotsResult = await Promise.all(promises);

    return snapshotsResult
      .flat()
      .flatMap((snap) => snap.docs)
      .map(
        (document_) =>
          ({
            ...document_.data(),
            is_recommended: true,
          }) as Location,
      );
  };

  searchNearestPlacesFromGoogle = async (
    radiusInM: number,
  ): Promise<GoogleSearchResult> => {
    return new Promise<GoogleSearchResult>((resolve) =>
      this.googlePlaceService.nearbySearch(
        {
          location: this.halfWayPoint!,
          radius: radiusInM,
          type: "restaurant",

          minPriceLevel: 2,
        },
        (results, status, pagination) => {
          resolve({
            results,
            status,
            c: pagination,
          });
        },
      ),
    );
  };

  convertGooglePlaceToLocation = (
    googlePlace: google.maps.places.PlaceResult,
  ): Location => {
    return {
      is_recommended: false,
      business_status: googlePlace.business_status || "",
      categories: [],
      formatted_address: googlePlace.formatted_address || "",
      geohash: geohashForLocation([
        googlePlace.geometry?.location?.lat() || 0,
        googlePlace.geometry?.location?.lng() || 0,
      ]),
      geometry: {
        lat: googlePlace.geometry?.location?.lat() || 0,
        lng: googlePlace.geometry?.location?.lng() || 0,
      },
      html_attributions: googlePlace.html_attributions || [],
      international_phone_number: googlePlace.international_phone_number || "",
      name: googlePlace.name || "",
      place_id: googlePlace.place_id || "",
      price_level: googlePlace.price_level || 0,
      priority: 0,
      rating: googlePlace.rating || 0,
      types: googlePlace.types || [],
      url: googlePlace.url || "",
      user_ratings_total: googlePlace.user_ratings_total || 0,
      vicinity: googlePlace.vicinity || "",
      website: googlePlace.website || "",
    };
  };

  getPlaceDetailsFromGoogle = async (
    placeId: string,
  ): Promise<google.maps.places.PlaceResult | null> => {
    const request = {
      placeId,
      fields: this.requestedFields,
    };
    return new Promise((resolve, reject) => {
      this.googlePlaceService.getDetails(request, (place, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !place) {
          console.error("Place not found");
          reject(null);
          return;
        }

        resolve(place);
      });
    });
  };

  private calculateSearchAreaRadius = (distance: number): number => {
    return (this.K * Math.pow(distance, 1 / this.P)) / 2;
  };

  private calculateGeometryHalfwayPoint = (
    point1: AddressObject,
    point2: AddressObject,
  ): google.maps.LatLngLiteral => {
    const middleLat =
      ((point1.coordinates?.lat ?? 0) + (point2.coordinates?.lat ?? 0)) / 2;
    const middleLng =
      ((point1.coordinates?.lng ?? 0) + (point2.coordinates?.lng ?? 0)) / 2;

    this.halfWayPoint = { lat: middleLat, lng: middleLng };

    return {
      lat: middleLat,
      lng: middleLng,
    };
  };
}
