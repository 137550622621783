import {
  ImportOutlined,
  InsertRowAboveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import Logo from "containers/MainLayout/components/Logo/Logo";
import { AppRoutesEnum } from "lib/constants/global";
import React, { PropsWithChildren, useCallback } from "react";
import { NavLink } from "react-router-dom";

import { useAuth } from "../../lib/utils/auth";
import AdminPanelAuth from "../../pages/AdminPanelAuth/AdminPanelAuth";
import styles from "./AdminLayout.module.scss";

const { Content, Footer, Sider } = Layout;

const menuItems = [
  {
    key: AppRoutesEnum.AdminPanelUpload,
    icon: UploadOutlined,
    title: "Upload locations",
    label: "Upload locations",
    link: AppRoutesEnum.AdminPanelUpload,
  },
  {
    key: AppRoutesEnum.AdminPanelLocations,
    icon: InsertRowAboveOutlined,
    title: "Locations table",
    label: "Locations table",
    link: AppRoutesEnum.AdminPanelLocations,
  },
  {
    key: AppRoutesEnum.AdminPanelImportExport,
    icon: ImportOutlined,
    title: "Import/Export",
    label: "Import/Export",
    link: AppRoutesEnum.AdminPanelImportExport,
  },
];

const AdminLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, authenticate, logout] = useAuth();
  const clickLogout = useCallback(() => {
    logout();
  }, []);

  if (!isAuthenticated) {
    return <AdminPanelAuth />;
  }

  return (
    <Layout className={styles.layout} hasSider={true}>
      <Sider>
        <div className="demo-logo-vertical" style={{ margin: "20px auto" }}>
          <Logo />
        </div>
        <Menu theme="dark" mode="inline">
          {menuItems.map(({ link, label, icon: Icon }) => (
            <NavLink
              to={link}
              key={link}
              style={({ isActive }) => ({
                color: isActive ? "#1890ff" : "aliceblue",
              })}
            >
              <div className={styles.menuItem}>
                <Icon />
                <span>{label}</span>
              </div>
            </NavLink>
          ))}
        </Menu>
        <Button
          type="primary"
          style={{ margin: "20px auto", display: "block" }}
          onClick={clickLogout}
        >
          Logout
        </Button>
      </Sider>
      <Layout>
        <Content style={{ margin: "24px 16px 0" }}>{children}</Content>
        <Footer style={{ textAlign: "center" }}>
          Halfway {process.env.REACT_APP_ENV || "undefined"} Admin Panel ©2023
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
