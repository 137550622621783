import { getHalfWay } from "api/halfway";
import { Halfway } from "lib/models/halfway";
import { useCallback, useMemo } from "react";

import { useNotFoundRedirect } from "./useNotFoundRedirect";

export const useFetchHalfway = () => {
  const { notFoundRedirect } = useNotFoundRedirect();

  const fetchHalfway = useCallback(
    async (halfwayId: string): Promise<Halfway> => {
      // eslint-disable-next-line unicorn/no-await-expression-member
      const halfway = (await getHalfWay(halfwayId)).data() as Halfway;

      if (!halfway) {
        void notFoundRedirect();
      }

      return halfway;
    },
    [notFoundRedirect],
  );

  return useMemo(() => ({ fetchHalfway }), [fetchHalfway]);
};
