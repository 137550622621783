import GoogleMap from "components/GoogleMap/GoogleMap";
import { SEARCH_AREA_RECTANGLE } from "lib/constants/google";
import { memo, useCallback, useEffect, useState } from "react";

//!REMOVE THE PAGE
const LocationForm = () => {
  const [map, setMap] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLoad = useCallback((map: any) => {
    const rectangle = new google.maps.Rectangle({
      bounds: SEARCH_AREA_RECTANGLE,
      editable: true,
      draggable: true,
    });

    const computedDistanceBetweenPoints =
      google.maps.geometry.spherical.computeDistanceBetween(
        { lat: SEARCH_AREA_RECTANGLE.north, lng: SEARCH_AREA_RECTANGLE.west },
        { lat: SEARCH_AREA_RECTANGLE.north, lng: SEARCH_AREA_RECTANGLE.east },
      );

    console.log("computedDistanceBetweenPoints", computedDistanceBetweenPoints);

    const distanceInKilometer = Math.ceil(
      Math.round(computedDistanceBetweenPoints) / 1000,
    );
    console.log("distanceIn", distanceInKilometer);
    // Calculate the distance between two points

    rectangle.setMap(map);
    rectangle.addListener("bounds_changed", () => {
      console.log(" rectangle.getBounds()", rectangle.getBounds());
    });

    map.fitBounds(SEARCH_AREA_RECTANGLE);
    setMap(map);
  }, []);

  useEffect(() => {
    if (!map) return;
  }, [map]);

  return (
    <div className="App">
      <GoogleMap handleOnLoad={onLoad} />
    </div>
  );
};

export default memo(LocationForm);
