import { Libraries } from "@react-google-maps/api";

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const GOOGLE_SCRIPTS_LIBRARIES: Libraries = ["places", "geometry"];
export const DEFAULT_ZOOM_LEVEL = 15;
export const DEFAULT_MAP_CENTER = {
  lat: 34.0522,
  lng: -118.2437,
};

export const DEFAULT_MAP_OPTS: google.maps.MapOptions = {
  // zoom: DEFAULT_ZOOM_LEVEL,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  // zoomControl: false,
};

export const SEARCH_AREA_RECTANGLE: google.maps.LatLngBoundsLiteral = {
  north: 34.305_242_736_625_516,
  south: 33.696_144_875_163_625,
  west: -118.827_301_757_812_52,
  east: -118.159_119_873_046_86,
};

export const AUTOCOMPLETE_OPTIONS: google.maps.places.AutocompleteOptions = {
  componentRestrictions: {
    country: "us",
  },
  bounds: SEARCH_AREA_RECTANGLE,

  strictBounds: true,
  fields: ["formatted_address", "geometry", "name", "place_id"],
};
