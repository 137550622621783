import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { FirebaseCollectionEnum } from "lib/constants/firebase";
import { db } from "lib/services/firebase";

import { Location } from "../../lib/models/location";

export const getLocations = async () =>
  // eslint-disable-next-line unicorn/no-await-expression-member
  (await getDocs(collection(db, FirebaseCollectionEnum.LOCATIONS))).docs.map(
    (document_) =>
      ({
        ...document_.data(),
        is_recommended: true,
      }) as Location,
  );

export const removeLocation = async (place_id: string) =>
  await deleteDoc(doc(db, FirebaseCollectionEnum.LOCATIONS, place_id));

export const uploadLocations = async (locations: Location[]) => {
  // Get a new write batch
  const batch = writeBatch(db);

  for (const location of locations) {
    if (!location.place_id) continue;

    const ref = doc(db, FirebaseCollectionEnum.LOCATIONS, location.place_id);
    batch.set(ref, {
      ...location,
      geometry: location.geometry,
    });
  }

  // Commit the batch
  await batch.commit();
};
