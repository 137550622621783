import { LocalStorageKeys } from "lib/constants/global";
import { nanoid } from "nanoid";

export const getUserGAKey = () => {
  const key = localStorage.getItem(LocalStorageKeys.USER_ID);

  if (key) return key;

  const newUserId = nanoid();
  localStorage.setItem(LocalStorageKeys.USER_ID, newUserId);

  return newUserId;
};
