import { message as AntdMessage } from "antd";
import { AppRoutesEnum } from "lib/constants/global";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const DEFAULT_MESSAGE = "Sorry, there is no Halfway location by that link";

export const useNotFoundRedirect = () => {
  const navigate = useNavigate();

  const notFoundRedirect = useCallback(
    async (message: string = DEFAULT_MESSAGE) => {
      navigate(AppRoutesEnum.ROOT);
      await AntdMessage.error(message);
      throw new Error(message);
    },
    [navigate],
  );

  return useMemo(() => ({ notFoundRedirect }), [notFoundRedirect]);
};
