import { AddressObject } from "lib/models/region";

export class GoogleDirection {
  googleDirectionsService: google.maps.DirectionsService;

  constructor() {
    this.googleDirectionsService = new google.maps.DirectionsService();
  }

  async calculateDurationFromLocationsToHalfwayPoint(
    point1: AddressObject,
    point2: AddressObject,
    halfWayPoint: google.maps.LatLngLiteral,
  ): Promise<number[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DIRECTION_REQUEST_OPTION: any = {
      travelMode: "DRIVING", // google.maps.TravelMode.DRIVING,
      drivingOptions: {
        departureTime: new Date(Date.now()),
        trafficModel: "bestguess", // google.maps.TrafficModel.BEST_GUESS,
      },
      transitOptions: {
        departureTime: new Date(Date.now()),
      },
    };

    // calculate the duration of journey
    // from location 1 to half way point
    // from location 2 to half way point
    return Promise.all([
      this.getTrafficTime({
        origin: point1.coordinates,
        destination: halfWayPoint,
        ...DIRECTION_REQUEST_OPTION,
      }),
      this.getTrafficTime({
        origin: point2.coordinates,
        destination: halfWayPoint,
        ...DIRECTION_REQUEST_OPTION,
      }),
    ]);
  }

  async getTrafficTime(
    request: google.maps.DirectionsRequest,
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      void this.googleDirectionsService.route(
        request,
        function (result, status) {
          if (status === google.maps.DirectionsStatus.OK) {
            const routeLegs = result?.routes[0]?.legs[0];

            const routeDuration =
              routeLegs?.duration_in_traffic?.value ||
              routeLegs?.duration?.value ||
              0;

            resolve(routeDuration);
          } else {
            console.error(`error fetching directions ${result}`);
            reject(0);
          }
        },
      );
    });
  }

  calculateDistanceOffset(minutes: number) {
    const thresholds = [
      { minutes: 5, distance: 0 },
      { minutes: 10, distance: 1 },
      { minutes: 15, distance: 2 },
      { minutes: 20, distance: 3 },
      { minutes: 25, distance: 4 },
      { minutes: 30, distance: 5 },
    ];

    const defaultDistance = 6;

    const matchingThreshold = thresholds.find(
      (threshold) => minutes < threshold.minutes,
    );

    return matchingThreshold ? matchingThreshold.distance : defaultDistance;
  }
}
