import { useJsApiLoader } from "@react-google-maps/api";

import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_SCRIPTS_LIBRARIES,
} from "../lib/constants/google";

export const useGoogleScript = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY || "",
    libraries: GOOGLE_SCRIPTS_LIBRARIES,
    language: "en",
  });

  return {
    isLoaded,
  };
};
