export const SHARED_SEARCH_QUERY = "shared";

export enum LocalStorageKeys {
  USER_ID = "userId",
}

export enum AppRoutesEnum {
  "ROOT" = "/",
  "PrivatePolicy" = "/private-policy",
  "TermsOfService" = "/terms",
  "Cookies" = "/cookies",
  "HalfwaySearch" = "/halfway/search",
  "HalfwayResult" = "/halfway/:halfwayId",
  "HalfwaySharedLocation" = "/halfway/:halfwayId/location/:locationId",
  "AdminPanelUpload" = "/admin/upload",
  "AdminPanelLocations" = "/admin/locations",
  "AdminPanelImportExport" = "/admin/import-export",
}
