import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation, useRoutes } from "react-router-dom";

import { ROUTES } from "./routes";

function App() {
  const location = useLocation();

  const routes = useRoutes(ROUTES);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location, location.pathname]);

  return routes;
}

export default App;
