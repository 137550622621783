import { useEffect, useState } from "react";

const callbacks: Array<(isAuthenticated: boolean) => void> = [];

function authenticate(login: string, password: string): boolean {
  if (login === "admin" && password === "HWay215") {
    localStorage.setItem("token", "admin");
    const isAuth = isAuthenticated();
    for (const callback of callbacks) callback(isAuth);
    return true;
  }

  return false;
}

function isAuthenticated(): boolean {
  return !!localStorage.getItem("token");
}

function logout(): void {
  localStorage.removeItem("token");
  const isAuth = isAuthenticated();
  for (const callback of callbacks) callback(isAuth);
}

export function useAuth(): [boolean, typeof authenticate, typeof logout] {
  const [isAuthenticatedState, setIsAuthenticated] = useState<boolean>(
    isAuthenticated(),
  );

  const handler = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };

  useEffect(() => {
    callbacks.push(handler);
    return () => {
      callbacks.splice(callbacks.indexOf(handler), 1);
    };
  }, []);

  return [isAuthenticatedState, authenticate, logout];
}
